<template>
  <div class="container-fluid">
    <breadcrumb>
    <img  src="static/img/controlframework.svg" class="breadcrumb-image" />
      <breadcrumb-item>
        <router-link to="/controlframeworks">Control Frameworks</router-link>
      </breadcrumb-item>
      <breadcrumb-item v-if="add" active>
        Add Control Framework
      </breadcrumb-item>
      <breadcrumb-item v-if="add == false" active>
        Control Framework Categories
      </breadcrumb-item>
    </breadcrumb>
    <div class="row">
      <div class="col-md-12">
        <form autocomplete="off">
          <card v-if="loaded">
            <div>
              <fg-input
                name="name"
                label="Name"
                v-validate="modelValidations.name"
                :error="getError('name')"
                v-model="model.name"
              ></fg-input>
              <fg-input label="Description">
                <textarea
                  name="Description"
                  v-model="model.description"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <checkbox v-model="checkboxValue">Active</checkbox>
            </div>
            <div class="card-footer text-right">
              <button
                type="button"
                @click="close"
                class="btn btn-fill btn-danger btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-times"></i>
                </span>
                Cancel
              </button>
              <button
                type="submit"
                @click.prevent="validate"
                @click="save"
                class="btn btn-fill btn-info btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-save"></i>
                </span>
                Save
              </button>
            </div>
          </card>
        </form>
      </div>
    </div>
    <div class="row" v-if="hideControlFrameworkCategory">
      <control-framework-category-main></control-framework-category-main>
    </div>
  </div>
</template>

<script>
import { Checkbox } from "src/components/index";
import ControlFrameworkCategoryMain from "./ControlFrameworkCategories/ControlFrameworkCategoryMain.vue";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";

export default {
  components: {
    Checkbox,
    ControlFrameworkCategoryMain,
    Breadcrumb,
    BreadcrumbItem,
  },
  data() {
    return {
      checkboxValue: true,
      model: {
        id: 0,
        name: "",
        description: "",
        isActive: true,
      },
      modelValidations: {
        name: {
          required: true,
        },
      },
      hideControlFrameworkCategory: true,
      loaded: false,
      add: false,
    };
  },
  created() {
    let self = this;
    self.get(self.$route.params.id);
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit("on-submit", this.controlFramework, isValid);
      });
    },
    get(id) {
      let self = this;
      if (id == undefined) {
        self.hideControlFrameworkCategory = false;
        self.add = true;
        self.loaded = true;
        return;
      }
      self.$store.state.services.controlFrameworkService
        .get(id)
        .then((r) => {
          self.model.id = r.data.id;
          self.model.name = r.data.name;
          self.model.description = r.data.description;
          var myValue = r.data.isActive == "True";
          self.model.isActive = myValue;
          self.checkboxValue = myValue;
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },
    save() {
      let self = this;
      self.$validator.validateAll().then((valid) => {
        if (valid) {
          if (self.model.id > 0) {
            self.model.isActive = self.checkboxValue.toString();
            self.$store.state.services.controlFrameworkService
              .update(self.model)
              .then((r) => {
                self.notifyVue("top", "right", "success");
                self.close();
              })
              .catch((err) => console.log(err));
          } else {
            self.model.isActive = self.checkboxValue.toString();
            self.$store.state.services.controlFrameworkService
              .add(self.model)
              .then((r) => {
                self.notifyVue("top", "right", "success");
                self.close();
              })
              .catch((err) => console.log(err));
          }
        }
      });
    },
    close(){
      let self = this;
      self.$router.push("/controlframeworks");
    },
    notifyVue(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span></br>Control Framework saved</br>&nbsp;</span>`,
        },
        icon: "fa fa-check",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    },
  },
};
</script>