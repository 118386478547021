<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <card v-if="loaded">
          <div slot="header">
            <h4 class="card-title">Control Framework Categories</h4>
          </div>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <button
                style="height: 40px"
                type="button"
                @click="$router.push(`controlframeworkcategories/new`)"
                class="btn btn-fill btn-info btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-plus"></i>
                </span>
                Add Control Framework Category
              </button>
              <fg-input
                type="search"
                class="mb-3"
                style="width: 200px"
                placeholder="Search records"
                v-model="searchQuery"
                aria-controls="datatables"
                v-on:input="getAll"
              />
            </div>
            <div class="col-sm-12">
              <el-table
                v-loading="loading"
                style="width: 100%"
                :data="this.controlFrameworkCategories"
              >
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                ></el-table-column>
                <el-table-column
                  :min-width="150"
                  fixed="right"
                  label="Status"
                >
                  <template slot-scope="props">
                    <i
                      v-if="props.row.isActive === 'True'"
                      class="fa fa-check status-active"
                    ></i>
                    <i v-else class="fa fa-times status-inactive"></i>
                  </template>
                </el-table-column>
                <el-table-column
                  :width="150"
                  :min-width="150"
                  fixed="right"
                  label="Actions"
                >
                  <template slot-scope="props">
                    <a
                      v-tooltip.top-center="'Survey'"
                      class="btn-info btn-simple btn-link"
                      @click="
                        $router.push(
                          `controlframeworkcategories/${props.row.id}/surveys`
                        )
                      "
                    >
                      <i class="fa fa-tasks"></i> </a
                    >&nbsp;
                    <a
                      v-tooltip.top-center="'Metrics'"
                      class="btn-info btn-simple btn-link"
                      @click="
                        $router.push(
                          `controlframeworkcategories/${props.row.id}/oemetrics`
                        )
                      "
                    >
                      <i class="fa fa-tachometer-alt"></i> </a
                    >&nbsp;
                    <a
                      v-tooltip.top-center="'Edit'"
                      class="btn-info btn-simple btn-link"
                      @click="
                        $router.push(
                          `controlframeworkcategories/${props.row.id}/edit`
                        )
                      "
                    >
                      <i class="fa fa-edit"></i>
                    </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <div class>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
              v-on:input="getAll"
            ></l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import $ from "jquery";
// import {mapState, mapMutations } from 'vuex'

export default {
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    pagedData() {
      let self = this;
      return self.controlFrameworkCategories.slice(self.from, self.to);
    },
    to() {
      let self = this;
      let highBound = self.from + self.pagination.perPage;
      if (self.total < highBound) {
        highBound = self.total;
      }
      return highBound;
    },
    from() {
      let self = this;
      return self.pagination.perPage * (self.pagination.currentPage - 1);
    },
    total() {
      let self = this;
      return self.pagination.total;
    },
  },
  data() {
    return {
      modals: {
        manageControlFrameworkCategories: false,
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      tableColumns: [
        {
          prop: "name",
          label: "Category",
          minWidth: 300,
        }
      ],
      controlFrameworkCategories: [],
      loading: true,
      loaded: false,
    };
  },

  created() {
    let self = this;
    self.getAll();
  },

  methods: {
    getAll() {
      let self = this;
      if (this.$route.params.id == undefined) return;
      self.$store.state.services.controlFrameworkCategoryService
        .getAll({
          headers: {
            Pagination: `${self.pagination.currentPage},${self.pagination.perPage}`,
          },
          params: {
            Filter: `${self.searchQuery}`,
            ControlFrameworkId: `${this.$route.params.id}`,
          },
        })
        .then((r) => {
          self.controlFrameworkCategories = r.data;
          self.headerValues = JSON.parse(r.headers.pagination);
          self.pagination.total = self.headerValues.TotalItems;
          self.pagination.currentPage = self.headerValues.CurrentPage;
          self.pagination.perPage = self.headerValues.ItemsPerPage;
          self.loading = false;
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style>
</style>
